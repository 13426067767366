import React from "react"
import {makeStyles} from "@material-ui/core/styles"
import {Grid, Typography, useMediaQuery} from "@material-ui/core"
import TeamCard from "../components/teamcard"
import Hero from "../components/hero"
import Aston from "../img/Team/aston.jpg"
import Dawn from "../img/Team/dawn.jpg"
import Keith from "../img/Team/keith.jpg"
import Mandy from "../img/Team/mandy.jpg"
import Elise from "../img/Team/elise.jpg"
import Karen from "../img/Team/karen.jpg"
import Gail from "../img/Team/gail.jpg"
import Jessica from "../img/Team/jessica.jpg"
import Jack from "../img/Team/jack.jpg"
import Ashleigh from "../img/Team/Ashleigh.jpg"
import Chris from "../img/Team/Chris.jpg"
import Nicole from "../img/Team/Nicole.jpg"

const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: "Work Sans",
        fontSize: "3rem",
        textAlign: "center",
        color: "#ededed",
        [theme.breakpoints.down("sm")]: {
            fontSize: "2.2rem",
        },
    },
    bodyText: {
        fontFamily: "Open Sans",
        fontSize: "1.3rem",
        color: "#ededed",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.2rem",
        },
    },
}))

export default function Team() {
    const classes = useStyles()
    const isPhone = useMediaQuery("(max-width: 600px)")
    return (
        <>
            <div id="team">
                <Grid container spacing={0} alignItems="center" justify="center">
                    <Grid item xs={isPhone ? 11 : 8}>
                        <Hero
                            title="Meet the Team"
                            bodyText="Meet our amazing team members who make all of this possible."
                        />
                        <div style={{height: "2vh"}}/>
                        <Grid container spacing={0} alignItems="stretch" justify="center">
                            <Grid item md={5}>
                                <TeamCard
                                    float
                                    name="Aston Avery"
                                    role="Founder"
                                    image={Aston}
                                    bio="
                    My name is Aston Avery. The reason for founding Team Avery Essex is because I've been through a lot of health and medical problems through my childhood which involved having operations. I feel that I would like to give back to people that are inspiring, those that help the community, and break through barriers they thought they could never reach. I'm also an ambassador for autism charity Anna Kennedy Online. I also wanted to do some campaigning as well as within the autism sector, to make sure that we're autism aware. My name is Aston Avery and I'm autistic and proud."
                                />
                            </Grid>
                            <Grid item md={5}>
                                <TeamCard
                                    name="Dawn Avery"
                                    role="Team Leader"
                                    image={Dawn}
                                    bio="My name is Dawn. I'm the emotional heartstrings behind Team Avery Essex. I am a proud mother and nana. I bring the emotions and artistic flair to the team and like to think I am a part of the drive behind Aston's dreams. Together, myself and Keith along with the rest of the amazing team help Aston to fufill his wishes. I project manage a lot of the team's fundraising events and plan ideas to help raise funds. Aston's dreams are the heart of what we want to achieve, and as a team I really do think we are doing it. I am so proud of what Aston has achieved, and can't wait to see what he does next."
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TeamCard
                                    name="Keith Avery"
                                    float
                                    role="Team Leader"
                                    image={Keith}
                                    bio="Hello, my name is Keith Avery. I am the father of Aston, the founder of the team, as well as his brother Aaron. My family are my world, and I am a strong supporter of everything Team Avery Essex stands for. I provide the manpower that enables Aston to carry out his discos and fundraising events. This includes transporting and building Aston's set, and any other heavy lifting work or manual labour. I have energy and empathy for each and every charity the team has supported. I am a firm leader, and I also oversee the funds raised to ensure they go to the most worthy of causes."
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TeamCard
                                    name="Mandy Richardson"
                                    role="Treasurer & Pamper Judge"
                                    image={Mandy}
                                    bio="I am Mandy Richardson, the Treasurer and a founding member of Team Avery. I first got to know the Avery family through the singing circuit where Aston and Jessica regularly sang. I was inspired by Aston's journey and the message he and his family put out about his story and everything they have experienced over the years. I have been involved with fundraising events, the Pamper Project, and have assisted Aston in his presentations at schools and continue to do so to bring awarenesss to anyone interested in finding out more about us."
                                />
                            </Grid>
                            <Grid item md={4}>
                                {" "}
                                <TeamCard
                                    name="Karen Ranford Hawley"
                                    role="Team Member & Pamper Judge"
                                    image={Karen}
                                    bio="My name is Karen Hawley, a mum of three from Basildon. I became a member of Team Avery Essex when it first started. I'm also the treasurer for the Reece Hawley Foundation. I helped to found the Reece Hawley Foundation after losing my son Reece to a brain tumour at the age of 21 in 2014. I now spend my spare time helping to run the foundation and being a part of Team Avery Essex. I am a judge on the panel of Aston's Pamper Project and feel privileged to help so many other deserving people every day."
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TeamCard
                                    name="Gail Rivers"
                                    float
                                    role="Team Member & Pamper Judge"
                                    image={Gail}
                                    bio="I'm Gail. I now live in Wales where I work part time. Previous to this, I lived and worked in Basildon. Dawn and I are friends from school and I was lucky enough to reacquaint with her a few years ago. At the time, I was teaching beauty therapy and had a great interest in helping women with cancer who were undergoing treatment to look and feel better. Makeovers, pampers, even if it was just giving someone some eyebrows to make them feel comfortable. One Sunday afternoon, me and Dawn met up and decided to expand this idea, which became the pamper project you now see today. The fact that the nominees are chosen by people who love and care for them make it all the more special. "
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TeamCard
                                    name="Elise Lawrence"
                                    role="Team Member"
                                    image={Elise}
                                    bio="While writing this bio, I asked some friends for their thoughts. Loon, supportive, big heart. In fact, 'loon' came up more than once! All I can really add is that I do what I can when I can, I always take serious things with a pinch of salt, try to find the funny side even in those darker times, be there for family and friends when they need me - normally airport runs and raffles, but hey, they thought of me first! I love or at least like most animals, and am a fan of the outdoors. I like arts and crafts, though I'm no good at it, but enjoy it regardless!"
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TeamCard
                                    name="Jessica Richardson"
                                    float
                                    role="Team Member"
                                    image={Jessica}
                                    bio="Hello! I'm Jessica Richardson. I'm a member of Team Avery. I first met the family when I used to sing locally and they were really supportive of me on The Voice Kids. As I got to know the family more, I wanted to help spread the word of their mission and with the help of John Summerton and my friends, The Averyettes, we recorded a version of This is Me from The Greatest Showman. This song really resonated with me that it is important to celebrate everyone's unique qualities; that people can be different but not less. This song is played in Aston's school presentations and I am delighted to continue to support Team Avery singing at their events."
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TeamCard
                                    name="Chris Cooper"
                                    float
                                    role="Team Member"
                                    image={Chris}
                                    bio="My Names Chris Cooper, I'm a Dad of 6 gorgeous kiddies and Husband to Ashley. My relationship with Team Avery started in 2016 when Aston interview me on the radio for a charity event that we was about to launch through United For Charity and since then it has grown into a solid partnership of helping others. Team Avery have helped many people all other the community and further included ourselves through pamper projects and more. Team Avery were there when we needed them the most and I can't wait to help continue their amazing work and help others."
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TeamCard
                                    name="Ashley Cooper"
                                    float
                                    role="Team Member"
                                    image={Ashleigh}
                                    bio="I'm Ashley wife to Chris and muma to our 6 gorgeous children. Life is pretty crazy and we're still adjusting to life with autism. The Averys have helped us along our journey, and without their support, we wouldn't be where we are today, so what better way to give back then to join and support them right back! knowing first hand how much joy these special pampers give and how important it is for everyone to feel seen, I've joined the best team💙."
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TeamCard
                                    name="Nicole Simmons"
                                    float
                                    role="Team Member"
                                    image={Nicole}
                                    bio="I’m Nicole a busy mum of 4 , I first met Aston, Dawn and Keith via my husband Lewis Simmons who has always been a keen fund raiser but now it’s my turn! The Avery family gave me so much strength & support & determination when my little boy was diagnosed with Autism , I now want to do all I can to help others, and let them know they are not alone on their  battle and acceptance in there journeys. More awareness is needed. Let the fund raising commence privileged to be part of Team Avery Essex"
                                />
                            </Grid>
                            <Grid item md={5}>
                                <TeamCard
                                    name="Jack Burns"
                                    float
                                    role="Website Developer"
                                    image={Jack}
                                    bio={`Hello! My name is Jack, and I'm a web designer. I created this site as a "donation" of sorts to TeamAveryEssex. I admire Aston and his family greatly and feel this is the least I could do for them. I've taken great care to make sure this site is the best it can be for the team, and I hope you enjoy the site as much as I enjoyed creating it! If you happen to be a fan of my work, why not get in touch with me? You can visit my site jaack.dev if you'd like!`}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={0}
                            alignItems="stretch"
                            justify="center"
                        ></Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
